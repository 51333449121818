import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// devices routing
const Devices = Loadable(lazy(() => import('./devices/Device')));

// ==============================|| MAIN ROUTING ||============================== //

const ModbusRoutes = {
    path: 'modbus',
    children: [
        {
            path: 'devices',
            element: <Devices />
        }
    ]
};

export default ModbusRoutes;
